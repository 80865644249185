<template>
  <el-dialog :title="'Fisa produs ' + selectedObject.Nume " :visible.sync="showPopup" id="popup_dialog" :fullscreen="true" class="my-dialog-class" append-to-body>
    <el-card style="margin: 5px 0px 5px 0px">
      <el-collapse accordion style="border: none">
        <el-collapse-item name="1" style="border: none">
          <template slot="title">
            <div slot="header" class="clearfix">
              <!-- <titlu-pagina
                Titlu="Produse"
                @on_add_clicked="show_dialog()"
                :AdaugaVisible="true"
              /> -->
            </div>
          </template>
          <div class="filtre">
            <el-form
              @submit.prevent.native="refresh_info()"
              label-position="top"
            >
              <el-row :gutter="20">
                <el-col >
                  <el-form-item label="Gestiune">
                    <el-select class="full-width" v-model="Filters.IdGestiune" filterable >
                      <el-option label="Toate" value="-1"></el-option>
                      <el-option v-for="item in Info.gestiuni" :key="'gestiuni' + item.Id" :label="item.Nume" :value="item.Id" />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item label='Interval'>
                    <el-date-picker class="full-width"                           
                        v-model="Filters.Data"
                        type="daterange"
                        range-separator="-"
                        start-placeholder="Data inceput"
                        end-placeholder="Data sfarsit"
                        :picker-options="datePickerOptions"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                
                <el-col style="width: 100%">
                  <div class="footer-form">
                    <el-button type="primary" native-type="submit" @click="refresh_info()">Aplica</el-button>
                    <el-button type="danger"  native-type="submit" @click="reset()">Reseteaza</el-button>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-card>

     <table cellpadding="10" class="tabel-data" style="margin-top: 10px; width: 100%">
      <thead>
          <tr >
            <th style="text-align: right;">Crt</th>
            <th style="text-align: right;">Data</th>
            <th >Actiune</th>
            <th style="text-align: right;">Cantitate</th>
            <th style="text-align: right;">P.U.</th>
            <th style="text-align: right;">Valoare</th>
            <th >Gestiune</th>
            <th style="text-align: right;">StocInitial</th>
            <th style="text-align: right;">StocFinal</th>
            <th style="text-align: right;">SoldInitial</th>
            <th style="text-align: right;">SoldFinal</th>
            <th>Document</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in Results" :key="item.TipMiscare + 'rand' + item.Id" :class=" item.TipMiscare ">
            <td style="text-align: right;" >{{item.Crt}}</td>
            <td style="text-align: right;" >{{item.Data}}</td>
            <td >{{item.TipMiscare}}</td>
            <td style="text-align: right;" >{{item.Cantitate}}</td>
            <td style="text-align: right;" >
              <div v-if="item.PretFaraTVA == 0">-</div>
              <div v-else>{{item.PretFaraTVA | format_money}}</div>
            </td> 
            <td style="text-align: right;" >
              <div v-if="item.ValoareFaraTVA == 0">-</div>
              <div v-else>{{item.ValoareFaraTVA | format_money}}</div>
            </td>
            <td >{{item.NumeGestiune}}</td>
            <td style="text-align: right;" >{{item.StocInitial}}</td>
            <td style="text-align: right;" >{{item.StocFinal}}</td>
            <td style="text-align: right;" >{{item.SoldInitial | format_money}}</td>
            <td style="text-align: right;" >{{item.SoldFinal | format_money}}</td>
            <td >
              <el-tooltip content="Deschide document">
                <el-button el-button type="text" @click="decodifica(item)" style="padding: 0px 0px;">
                {{item.Document}} 
                </el-button>
              </el-tooltip>
            </td>
          </tr>
        </tbody>
      </table>

     <!-- <el-table :header-cell-style="{ background: '#393E46' }" el-table :data="Results">
      <el-table-column prop="Crt" label="NrCrt"></el-table-column>
      <el-table-column prop="Data" label="Data"></el-table-column>
      <el-table-column prop="TipMiscare" label="Actiune"></el-table-column>
      <el-table-column prop="Cantitate" label="Cantitate"></el-table-column>
      <el-table-column prop="PretFaraTVA" label="P.U."></el-table-column>
      <el-table-column prop="ValoareFaraTVA" label="Valoare"></el-table-column>
      <el-table-column prop="NumeGestiune" label="Gestiune"></el-table-column>
      <el-table-column prop="StocInitial" label="StocInitial"></el-table-column>
      <el-table-column prop="StocFinal" label="StocFinal"></el-table-column>
      <el-table-column prop="SoldInitial" label="SoldInitial"></el-table-column>
      <el-table-column prop="SoldFinal" label="SoldFinal"></el-table-column>
      <el-table-column style="text-align: center;" fixed="right" label="Document" width="160px">
        <template slot-scope="scope">
          <el-tooltip content="Deschide document">
            <el-button type="text" @click="decodifica(scope.row)">
            {{scope.row.Document}} 
            </el-button>

          </el-tooltip>
        </template>
      </el-table-column>
    </el-table> -->

    <el-pagination
      @size-change="refresh_info"
      @current-change="refresh_info"
      :page-size.sync="PaginationInfo.PerPage"
      :current-page.sync="PaginationInfo.Page"
      :total="PaginationInfo.RowCount"
      layout="pager"
    />
    <span slot="footer" class="dialog-footer">
      <el-button @click="showPopup = false"> Inchide </el-button>
      <!-- <el-button type="primary" @click="save"> Salveaza </el-button> -->
    </span>

  <Niruri-dialog      ref="Nir"      @save="refresh_info" />
  <Facturi-dialog     ref="Factura"  @save="refresh_info" />
  <Transferuri-dialog ref="Transfer" @save="refresh_info" />
  <Bonuri_consum-dialog ref="BonConsum" @save="refresh_info" />
  </el-dialog>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Niruri_dialog from "@/pages/niruri/Niruri_dialog.vue";
import Facturi_dialog from "@/pages/facturi/Facturi_dialog.vue";
import Transferuri_dialog from "@/pages/transferuri/Transferuri_dialog.vue";
import Bonuri_consum_dialog from "@/pages/bonuri_consum/Bonuri_consum_dialog.vue";

export default {
  name: "Produse_dialog",
  extends: BasePage,
  components: {
    "Niruri-dialog": Niruri_dialog,
    "Facturi-dialog": Facturi_dialog,
    "Transferuri-dialog": Transferuri_dialog,
    "Bonuri_consum-dialog": Bonuri_consum_dialog,
  },
  data() {
    return {
      datePickerOptions: {firstDayOfWeek: 1},
      id:-1,
      baseUrl: "",
      showPopup: false,
      Results: [],
      selectedObject: {
        Nume: "",
      },
      Filters: {
        IdGestiune: "-1",
        Data: [],
      },
      Info: {
        gestiuni: [],
      },
      OrderBy: {},
      PaginationInfo: {
        Page: 1,
        PerPage: 10,
        RowCount: 0,
        PageSizes: [10, 25, 50, 100, 200],
      },
    };
  },
  methods: {
     async show_me(id) {
      this.id = id;
      await this.refresh_info()
      this.showPopup = true;

    },
    async get_info() {
      var response = await this.post("produse/get_info_fisa");
      this.Info.gestiuni = response.gestiuni;
      // this.refresh_info();
    },

    async refresh_info() {
      var result = await this.post("produse/get_fisa", {id: this.id, OrderBy: this.OrderBy, PaginationInfo: this.PaginationInfo, Filters: this.Filters,});
        this.selectedObject = result.Item;
        this.Results = result.Fisa;
        this.PaginationInfo = result.PaginationInfo;

    },

    decodifica(ref_tranzactie){
      var x = ref_tranzactie.RefDocument;
      var tip = x.split("-")[0]; 
      this.$refs[tip].show_me(x.split("-")[1]);
    },

    reset() {
      this.Filters = {
        IdGestiune: "-1",
        Data: [],
      };
      this.refresh_info();
    },

  },

  mounted: function () {
    this.baseUrl = settings.BASE_URL;
    this.get_info();
  },
};
</script>


<style lang="less" scoped>

.filtre {
  width: 100% !important;
  display: grid;
  grid-template-columns: repeat(2);
  .el-col {
    width: 33%;
  }

  // grid-auto-rows: 100px;
  .el-input-number {
    width: 100% !important;
  }

  .tabel-data {
    word-break: keep-all;
    
  }

}
</style>